import { IntegrationResponse } from '../../../../api/petcloudapi/api';
import { useState } from 'react';
import Button from '../../../../elements/button/Button';
import { useTranslation } from 'react-i18next';
import Popup from '../../../../elements/popup/Popup';
import { useUser } from '../../../../contexts/auth/User';
import ProgressBar from '../../../../elements/progressbar/ProgressBar';
import InpettoClientBasedSetupUrl from './steps/url/InpettoClientBasedSetupUrl';
import InpettoClientBasedCredentials from './steps/credentials/InpettoClientBasedCredentials';
import InpettoClientBasedPlugin from './steps/plugin/inpettoclientbasedplugin/InpettoClientBasedPlugin';
import InpettoClientBasedConnectionTest from './steps/connectiontest/InpettoClientBasedConnectionTest';
import InpettoClientBasedSuccess from './steps/success/InpettoClientBasedSuccess';

enum SetupStep {
  Url,
  Credentials,
  Plugin,
  ConnectionTest,
  Success,
}

interface InpettoClientBasedSetupProps {
  integration: IntegrationResponse;
  manufacturerId?: string | null;
  skipPopup?: boolean;
  onPopupClose?: () => void;
}

const InpettoClientBasedSetup: React.FC<InpettoClientBasedSetupProps> = ({
  integration,
  manufacturerId,
  skipPopup,
  onPopupClose,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.integrations',
  });
  const { user } = useUser();
  const [step, setStep] = useState(SetupStep.Url);
  const [popup, setPopup] = useState(skipPopup ?? false);

  const mId = manufacturerId ?? user?.manufacturerId;

  const renderStep = () => {
    switch (step) {
      case SetupStep.Url:
        return (
          <InpettoClientBasedSetupUrl
            onNext={() => setStep(SetupStep.Credentials)}
            manufacturerId={mId}
            integration={integration}
          />
        );
      case SetupStep.Credentials:
        return (
          <InpettoClientBasedCredentials
            onNext={() => setStep(SetupStep.Plugin)}
            manufacturerId={mId}
            integration={integration}
          />
        );
      case SetupStep.Plugin:
        return (
          <InpettoClientBasedPlugin
            onNext={() => setStep(SetupStep.ConnectionTest)}
            integration={integration}
          />
        );
      case SetupStep.ConnectionTest:
        return (
          <InpettoClientBasedConnectionTest
            integration={integration}
            manufacturerId={mId}
            onNext={() => setStep(SetupStep.Success)}
          />
        );
      case SetupStep.Success:
        return <InpettoClientBasedSuccess />;
    }
  };

  return (
    <div className={'inpettoClientBasedSetup'}>
      {!skipPopup ? (
        <Button
          cta={t('install')}
          width={'minimal'}
          look={'secondary'}
          action={() => setPopup(true)}
        />
      ) : null}
      <Popup
        toggled={popup}
        width={'30%'}
        close={() => {
          setPopup(false);
          if (onPopupClose) onPopupClose();
        }}
      >
        <ProgressBar
          total={4}
          progress={step}
          hideDetail={true}
          style={'cardTop'}
          colorOnCompletion={'var(--color-success)'}
        />
        {renderStep()}
      </Popup>
    </div>
  );
};

export default InpettoClientBasedSetup;
