import './inpettoclientbasedplugin.css';
import { IntegrationResponse } from '../../../../../../../api/petcloudapi/api';
import { useTranslation } from 'react-i18next';
import PluginDownload from './plugindownload/PluginDownload';
import StepNavigation from '../../../stepnavigation/StepNavigation';

interface InpettoClientBasedPluginProps {
  onNext: () => void;
  integration: IntegrationResponse;
}

const InpettoClientBasedPlugin: React.FC<InpettoClientBasedPluginProps> = ({
  onNext,
  integration,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.integrations.setup.inpettoClientBased.plugin',
  });

  return (
    <div className={'inpettoClientBasedPlugin'}>
      <div className={'popup-title'}>{t('title')}</div>
      <div className={'global-textElement'} style={{ maxWidth: 400 }}>
        {t('text')}
      </div>
      <div className={'inpettoClientBasedPlugin-gif'}></div>
      <div className={'inpettoClientBasedPlugin-downloads'}>
        <PluginDownload plugin={plugins[integration.identifier]} />
      </div>
      <StepNavigation onNext={onNext} />
    </div>
  );
};

export type DownloadablePlugin = {
  name: string;
  fileName: string;
  fileSize: string;
  url: string;
  requiredVersions: {
    name: string;
    release: string;
  }[];
};

const plugins: { [key: string]: DownloadablePlugin } = {
  Shopware: {
    name: 'Plugin für Shopware',
    fileName: 'inpetto_shopware_v1.zip',
    fileSize: '1.1 KB',
    url: 'https://s3.fr-par.scw.cloud/inpetto-assets/pcmf/integrationsetup/plugins/inpetto_shopware_v1.zip',
    requiredVersions: [
      {
        name: 'PHP',
        release: '8.0',
      },
      {
        name: 'Shopware',
        release: '5.0',
      },
    ],
  },
  Magento: {
    name: 'Magento Plugin v0.0.10',
    fileName: 'magento-inpetto-connect-v0.0.10.zip',
    fileSize: '1.1 MB',
    url: 'https://s3.fr-par.scw.cloud/inpetto-assets/pcmf/integrationsetup/plugins/magento-inpetto-connect-v0.0.10.zip',
    requiredVersions: [
      {
        name: 'PHP',
        release: '>= 7.4',
      },
      {
        name: 'Magento',
        release: '^2.4.4',
      },
    ],
  },
};

export default InpettoClientBasedPlugin;
