import './inpettoclientbasedconnectiontest.css';
import { IntegrationResponse } from '../../../../../../api/petcloudapi/api';
import { useTranslation } from 'react-i18next';
import useInpettoClientBasedIntegrationApi from '../../hooks/useInpettoClientBasedIntegrationApi';
import { useMemo, useState } from 'react';
import { InpettoClientBasedIntegrationStatusResponse } from '../../../../../../api/shopwareapi';
import Button from '../../../../../../elements/button/Button';
import { LoadingContainer } from '../../../../../../elements/loading/Loading';
import ConnectionTestResults from './results/ConnectionTestResults';
import { EmptyState } from '../../../../../../elements/emptystate/EmptyState';
import empty from '../../../../../../../assets/img/app_connect.svg';

interface InpettoClientBasedConnectionTestProps {
  onNext: () => void;
  integration: IntegrationResponse;
  manufacturerId?: string | null;
}

const InpettoClientBasedConnectionTest: React.FC<
  InpettoClientBasedConnectionTestProps
> = ({ integration, manufacturerId, onNext }) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.integrations.setup.inpettoClientBased.connectionTest',
  });
  const { integrationStatusGet } = useInpettoClientBasedIntegrationApi(
    integration.identifier
  );
  const [response, setResponse] = useState<
    InpettoClientBasedIntegrationStatusResponse | null | undefined
  >(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getStatus = () => {
    if (manufacturerId) {
      setResponse(null);
      setIsSubmitting(true);
      integrationStatusGet(manufacturerId).then((response) => {
        setResponse(response);
        setIsSubmitting(false);
      });
    }
  };

  const statusArray = useMemo(() => {
    if (response) {
      const { versions, errors, ...rest } = response;
      return Object.entries(rest);
    } else {
      return null;
    }
  }, [response]);

  return (
    <div className={'inpettoClientBasedConnectionTest'}>
      <div className={'popup-title'}>{t('title')}</div>
      <div className={'global-textElement'} style={{ maxWidth: 400 }}>
        {t('text')}
      </div>
      {response === null ? (
        <LoadingContainer />
      ) : response && statusArray ? (
        <ConnectionTestResults response={response} statusArray={statusArray} />
      ) : (
        <EmptyState img={empty} size={200} />
      )}
      <div className={'global-cardActions global-cardActions-margin'}>
        {!statusArray || !!statusArray.find(([key, statusOk]) => !statusOk) ? (
          <Button
            cta={t('cta_test')}
            width={'minimal'}
            look={'primary'}
            action={getStatus}
            isLoading={isSubmitting}
            active={!!manufacturerId}
            inactiveMessage={t('noManufacturerId')}
          />
        ) : (
          <Button
            cta={t('cta_finish')}
            width={'minimal'}
            look={'save'}
            action={onNext}
          />
        )}
      </div>
    </div>
  );
};

export default InpettoClientBasedConnectionTest;
